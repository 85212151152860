import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import styled from 'styled-components'

import toHandle from 'utils/to-handle'

/**
 * @typedef LocationDirectoryGridComponentStaticQueryResult
 * @property {Object} allSanityLocation
 * @property {Object[]} allSanityLocation.states
 * @property {string} allSanityLocation.states[].fieldValue
 */

export const LocationDirectoryGrid = props => {
  /**
   * @type {LocationDirectoryGridComponentStaticQueryResult}
   */
  const {
    allSanityLocation: { states },
  } = useStaticQuery(graphql`
    query locationDirectorySectionComponent {
      allSanityLocation(filter: { status: { ne: "permanently-closed" } }) {
        states: group(field: state) {
          fieldValue
        }
      }
    }
  `)
  return (
    <LocationDirectoryGridList {...props}>
      {states.map(({ fieldValue: state }) => (
        <li key={state}>
          <Link to={`/locations/directory/${toHandle(state)}/`} key={state}>
            {state}
          </Link>
        </li>
      ))}
    </LocationDirectoryGridList>
  )
}

const LocationDirectoryGridList = styled.ul`
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    display: block;
    margin-bottom: 8px;

    a {
      color: ${props => props.theme.color.g400};
      display: inline-block;
      font-size: 18px;
      font-weight: 400;
      text-decoration: none;

      :hover,
      :focus {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: 600px) {
    columns: 2;
    column-gap: 16px;
    column-fill: balance;

    li {
      width: 50%;
    }
  }

  @media (min-width: 900px) {
    columns: 3;

    li {
      width: 33.33%;
    }
  }

  @media (min-width: 1200px) {
    columns: 4;

    li {
      width: 25%;
    }
  }
`
