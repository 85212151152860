import React from 'react'
import Content from '~/components/new/content'
import Spacer from '~/components/new/spacer'

export const BreadcrumbsSection = props => {
  const { children, kind = 'full', yPadding = false, ...rest } = props
  return (
    <Content kind={kind} yPadding={yPadding} {...rest}>
      <Spacer size='m' type='padding' variable />
      {children}
    </Content>
  )
}
