import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '~/components/layout'
import Content from '~/components/new/content'
import { H1, P } from '~/components/new/typography'
import Spacer from '~/components/new/spacer'
import { LocationDirectoryGrid } from '~/components/location-directory-grid'
import { Seo } from '~/components/seo'
import { BreadcrumbsSection } from '~/components/new/breadcrumbs/breadcrumbs-section'
import { BreadcrumbsNav } from '~/components/new/breadcrumbs/breadcrumbs-nav'

const breadcrumbs = [
  {
    name: 'Hutson Inc',
    displayName: 'Home',
    link: '/',
  },
  {
    name: 'Locations',
    link: '/locations/',
  },
  {
    name: 'Directory',
    link: '/locations/directory/',
  },
]

/**
 * @param {import('gatsby').PageProps<LocationDirectoryPageData>} props
 */
const LocationDirectoryPage = props => {
  const {
    data: {
      allSanityLocation: { states: allSanityLocationStates },
      locationStats: { totalCount },
    },
  } = props
  return (
    <Layout kind='white'>
      <Seo
        title='Location Directory'
        description={`Hutson Inc has ${totalCount} stores conveniently located across ${allSanityLocationStates.length} states. Find a Hutson store near you for great products, service, and parts.`}
        breadcrumbs={breadcrumbs}
      />
      <PageContent>
        <BreadcrumbsSection>
          <BreadcrumbsNav items={breadcrumbs} />
        </BreadcrumbsSection>
        <Content kind='full' className='top-content'>
          <H1>Location Directory</H1>
          <P className='subtitle'>
            {totalCount} locations across {allSanityLocationStates.length} states
          </P>
          <Spacer size='m' />
          <LocationDirectoryGrid />
        </Content>
      </PageContent>
    </Layout>
  )
}

const PageContent = styled.div`
  .top-content {
    padding: 24px 0 36px;
  }

  @media (min-width: 900px) {
    .top-content {
      padding: 48px 64px 64px;
    }
  }

  .subtitle {
    margin: 0;
  }
`

/**
 * @typedef LocationDirectoryPageData
 * @property {Object} allSanityLocation
 * @property {Object[]} allSanityLocation.states
 * @property {string} allSanityLocation.states[].fieldValue
 * @property {number} allSanityLocation.states[].totalCount
 * @property {Object} locationStats
 * @property {number} locationStats.totalCount
 */

export const pageQuery = graphql`
  query locationDirectoryPage {
    allSanityLocation(
      filter: { status: { ne: "permanently-closed" } }
      sort: { fields: title, order: ASC }
    ) {
      states: group(field: state) {
        fieldValue
      }
    }
    locationStats: allSanityLocation(
      filter: { status: { ne: "permanently-closed" }, retailLocation: { eq: true } }
    ) {
      totalCount
    }
  }
`

export default LocationDirectoryPage
