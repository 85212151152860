const isDefined = require('./is-defined')

/**
 * Convert a string to a handle
 * @param {string} str - String to convert
 * @returns {string}
 */
const toHandle = str => {
  if (isDefined(str)) {
    return str
      .toLowerCase()
      .replace(/'/g, '')
      .replace(/[\W_]+/g, '-')
      .replace(/(^-+|-+$)/g, '')
  }
  return str
}

module.exports = toHandle
